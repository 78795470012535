import { Outlet } from "react-router";
import { Box, Drawer, DrawerContent, useDisclosure } from "@chakra-ui/react";
import React, { useEffect } from "react";
import SidebarContent from "../../components/side-bar-contents/SideBarContents";
import MobileNav from "../../components/mobile-nav/MobileNav";
import { useIsLoginQuery } from "../../services/api.service";
import { useDispatch } from "react-redux";
import { clearUser, setAuthentication } from "../../store/reducers/common/common-store";
import { useNavigate } from "react-router-dom";

const InternalLayout = () => {

    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data, isFetching, status } = useIsLoginQuery({})

    const dispatch = useDispatch()

    useEffect(() => {
        const user = data?.message?.user;

        if (!isFetching && status == 'fulfilled') {
            if (user && user != 'Guest') {
                dispatch(setAuthentication(data.message))
            } else {
                dispatch(clearUser());
                navigate("/", { replace: true })
                window.location.reload()
            }
        }
    }, [data])

    return (
        <>
            {!isFetching && (
                <Box minH="100vh" bg={'gray.100'}>
                    <SidebarContent
                        onClose={() => onClose}
                        display={{ base: 'none', md: 'block' }}
                    />
                    <Drawer
                        autoFocus={false}
                        isOpen={isOpen}
                        placement="left"
                        onClose={onClose}
                        returnFocusOnClose={false}
                        onOverlayClick={onClose}
                        size="full">
                        <DrawerContent>
                            <SidebarContent onClose={onClose} />
                        </DrawerContent>
                    </Drawer>

                    <MobileNav onOpen={onOpen} />

                    <Box ml={{ base: 0, md: 60 }} h={'full'} p="4">
                        <Outlet />
                    </Box>
                </Box>
            )}
        </>
    )
}
export default InternalLayout