import _ from "lodash"
import { Box } from "@chakra-ui/react";
import { ACTIVITY } from "../../consts/methods.consts";
import { LuActivity } from "react-icons/lu"
import { GrDocumentText } from "react-icons/gr"
import { useMethodQuery } from "../../services/api.service";
import { Step, StepDescription, Stepper,} from '@chakra-ui/react'

interface IActivitiesProps {
    doctype: string,
    ref_doctype: string | undefined
}

const Activities = ({ doctype, ref_doctype }: IActivitiesProps) => {

    const { data: activity } = useMethodQuery({
        method: ACTIVITY.GET_ACTIVITIES,
        body: {
            ref_doctype,
            doctype
        }
    })

    const activityList = _.get(activity, "message", [])

    return (
        <Stepper index={1} orientation='vertical' gap='2px'>
            {activityList.map((step: any, index: any) => (
                <Step key={index} className={`flex justify-center items-center my-2 ${index !== 0 ? 'mt-2' : ''}`}>
                    {index === activityList.length - 1 ? (
                        <div className="border border-black border-opacity-50 bg-white bg-opacity-50 rounded-full p-1">
                            <GrDocumentText />
                        </div>
                    ) : (
                        <div className="border border-black border-opacity-50 bg-white bg-opacity-50 rounded-full p-1">
                            <LuActivity />
                        </div>
                    )}
                    <Box>
                        <StepDescription className="flex gap-2">
                            <p>{step.change} </p><p className="opacity-50">{step.pretty_date}</p></StepDescription>
                    </Box>
                </Step>
            ))}
        </Stepper>
    )
}

export default Activities