import _ from 'lodash'
import * as Yup from "yup";
import { COLUMN } from "./PoLinesDrawer";
import { useToast, Button } from "@chakra-ui/react";
import { useState } from "react";
import { SHIPMENT } from "../../../consts/methods.consts";
import { Checkbox, } from '@chakra-ui/react'
import { useParams } from "react-router-dom";
import { FormInput } from "../../../components/form-controllers/FormInput";
import ChakraModal from "../../../components/chakra-modal/ChakraModal";
import ChakraTable from "../../../components/chakra-table/ChakraTable";
import { useFormik } from "formik";
import { ToastService } from '../../../services/toast.service';
import { useExecuteMutation } from "../../../services/api.service";

interface ICreateNewShipmentModal {
    isOpen: any,
    onClose: any,
    data: any
}

const ShippingSummaryModal = ({ data = [], isOpen, onClose }: ICreateNewShipmentModal) => {
    const [create] = useExecuteMutation()
    const { shipment_id } = useParams()
    const toast = new ToastService(useToast())
    const [isChecked, setIsChecked] = useState(false)

    const formik = useFormik({
        initialValues: { short_shipment_reason: "" },
        validationSchema: Yup.object({
            short_shipment_reason: Yup.string().when('is_short_shipment', {
                is: true,
                then: Yup.string().required("Reason is required!"),
            }),
        }),
        onSubmit: async (values: any) => {
            try {
                await create({
                    method: SHIPMENT.PROCEED_WITH_SHORT_SHIPMENT,
                    body: {
                        id: shipment_id,
                        is_short_shipment: isChecked ? 1 : 0,
                        short_shipment_reason: _.get(values, 'short_shipment_reason')
                    }
                }).unwrap();

                toast.setDescription("The shipment is proceeded to shipping with short shipment").showSuccessToast()
                formik.resetForm()
                onClose();

            } catch (e: any) {
                toast.setDescription("Please pick at least one pallet item").showErrorToast()
            }
        }
    })

    const onClickHandler = () => {
        if (isChecked) {
            formik.submitForm();
        }
        else {
            onClose();
        }
    }

    return (
        <ChakraModal size={"3xl"} primaryButtonText={isChecked ? 'Proceed' : 'Got it'} hideCancelButton={!isChecked}
            primaryAction={onClickHandler} modalTitle="Shipment Summary" isOpen={isOpen} onClose={onClose}>
            <div className="border rounded-sm">
                <ChakraTable
                    disablePointer
                    size={"sm"}
                    onSelected={() => { }}
                    columns={COLUMN}
                    data={data}
                />
            </div>
            <Checkbox my={3} isChecked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} >Proceed with short shipment</Checkbox>
            {isChecked ?
                <FormInput isRequired type={"text"} variant={"textArea"} formik={formik} name={"short_shipment_reason"} label={"Reason for the short shipment"} />
                : null}
        </ChakraModal>

    )
}

export default ShippingSummaryModal;