import { Box, BoxProps, CloseButton, Flex, HStack, Icon, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { useNavigate } from "react-router-dom";
import { BsFillHouseDoorFill, FaShip } from "../../icons";

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

export type NavMenuSubPath = {
    name: string,
    icon: IconType,
    path: string
}

interface LinkItemProps {
    name: string;
    icon: IconType;
    path?: string,
    subPath?: Array<NavMenuSubPath>
}

const LinkItems: Array<LinkItemProps> = [
    {
        name: 'Orders',
        icon: BsFillHouseDoorFill,
        path: '/app',
        subPath: []
        // subPath: [
        //     {
        //         icon: HiOutlineNewspaper,
        //         name: "Purchase Order",
        //         path: "/app/item-master"
        //     }
        // ]
    },
    {
        name: 'Shipment',
        icon: FaShip,
        path: '/app/shipments',
        subPath: []
        // subPath: [
        //     {
        //         icon: HiOutlineNewspaper,
        //         name: "Purchase Order",
        //         path: "/app/item-master"
        //     }
        // ]
    }
];

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    const navigation = useNavigate()

    const navigationHandler = (path: any) => {
        if (path) {
            navigation(path)
        }
    }


    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex onClick={() => {
                navigation('/app')
            }} cursor={"pointer"} h="16" alignItems="center" mx="8" justifyContent="space-between">
                <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
                    JB Connector
                </Text>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            {LinkItems.map((link: any) => (
                <Stack onClick={() => navigationHandler(link.path)} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
                    <Flex
                        align="center"
                        p="3"
                        cursor={"pointer"}
                        mx="4"
                        {...rest}>
                        {link.icon && (
                            <HStack>
                                <Icon
                                    mr="2"
                                    fontSize="16"
                                    _groupHover={{
                                        color: 'white',
                                    }}
                                    as={link.icon}
                                />
                                <Text mb={2} fontSize={"sm"}>{link.name}</Text>
                            </HStack>
                        )}

                        {link.subPath.length > 0 ? (
                            link.subPath.map((line: any, index: any) => (
                                <HStack
                                    onClick={() => navigation(line.path)}
                                    borderRadius="lg"
                                    role="group"
                                    cursor="pointer"
                                    _hover={{
                                        bg: 'cyan.400',
                                        color: 'white',
                                    }}
                                    mt={!index ? 3 : 2} ps={4} py={2}>
                                    <Icon
                                        mr="4"
                                        fontSize="16"
                                        _groupHover={{
                                            color: 'white',
                                        }}
                                        as={line.icon}
                                    />
                                    <Text fontSize={"sm"}>{line.name}</Text>
                                </HStack>
                            ))
                        ) : null}
                    </Flex>
                </Stack>
            ))}
        </Box>
    );
};

export default SidebarContent