export enum PO {
    GET_PO_LIST = "jb_connector.api.po.get_po_list",
    GET_PO = "jb_connector.api.po.get_po",
    GET_PO_ITEM_LIST = "jb_connector.api.po.get_purchase_order_item_list_by_shipment"
}

export enum PALLET {
    NEW_PALLET = "jb_connector.api.pallet.create_a_new_pallet",
    GET_PALLET_LIST = "jb_connector.api.pallet.get_pallet_list",
    GET_ITEM_LIST_FOR_SHIPMENT = "jb_connector.api.pallet.get_item_list_for_shipment",
    GET_PALLET_ALLOCATIONS = "jb_connector.api.pallet.get_pallet_allocations",
    REMOVE_ITEM_ALLOCATION = "jb_connector.api.pallet.remove_allocation",
    CREATE_ITEM_ALLOCATION = "jb_connector.api.pallet.create_item_allocation",
    PRINT_PALLET_ITEM_QR = "jb_connector.api.pallet.print_pallet_item_qr",
    PRINT_PALLET_QR = "jb_connector.api.pallet.print_pallet_qr",
    REMOVE_PALLET = "jb_connector.api.pallet.remove_pallet",
    GET_PALLET_TYPE = "jb_connector.api.pallet.get_pallet_info",
    UPDATE_PALLET_TYPE = "jb_connector.api.pallet.update_pallet_type"
}

export enum SHIPMENT {
    GET_SHIPMENT_LIST = "jb_connector.api.shipment.get_shipment_list",
    GET_PO_LIST_FOR_DROPDOWN = "jb_connector.api.shipment.get_po_list_for_dropdown",
    CREATE_SHIPMENT = "jb_connector.api.shipment.create_shipment",
    GET_PO_LIST_BY_SHIPMENT = "jb_connector.api.shipment.get_purchase_order_list_by_shipment",
    GET_SHIPMENT_STATUS = "jb_connector.api.shipment.get_shipment_status",
    PROCEED_TO_SHIPPING = "jb_connector.api.shipment.proceed_to_shipping",
    CANCEL_SHIPMENT = "jb_connector.api.shipment.cancel_shipment",
    GET_SHIPMENT_TYPE = "jb_connector.api.shipment.get_shipment_type",
    UPDATE_SHIPMENT_TYPE = "jb_connector.api.shipment.change_shipment_status",
    GET_CONTAINER_TYPE = "jb_connector.api.shipment.get_container_type",
    GET_SHIPMENT_DETAILS = "jb_connector.api.shipment.get_shipment_details",
    UPDATE_SHIPMENT_DATES = "jb_connector.api.shipment.update_shipment_dates",
    GET_EXPIRE_DATES_OF_PALLETS_IN_A_SHIPMENT = "jb_connector.api.shipment.expire_dates_of_pallet_items_in_a_shipment",
    GET_BATCH_NUMBERS_OF_PALLETS_IN_A_SHIPMENT = "jb_connector.api.shipment.batch_numbers_of_pallet_items_in_a_shipment",
    PROCEED_WITH_SHORT_SHIPMENT = "jb_connector.api.shipment.proceed_with_short_shipment",
    GET_SHORT_SHIPMENT_STATE ="jb_connector.api.shipment.get_is_short_shipment_state"
}

export enum COMMENT {
    GET_COMMENTS = "jb_connector.api.comments.get_comments",
    ADD_COMMENT = "jb_connector.api.comments.add_comment",
    UPDATE_COMMENT = "jb_connector.api.comments.update_comment",
    DELETE_COMMENT = "jb_connector.api.comments.delete_comment",
}

export enum ACTIVITY {
    GET_ACTIVITIES = "jb_connector.api.versions.get_versions"
}

export enum ATTACHMENT {
    GET_ATTACHMENTS = "jb_connector.api.attachments.get_attachments",
    DELETE_ATTACHMENT = "jb_connector.api.attachments.delete_attachment"
}