import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    roles:[]
}

const slice: any = createSlice({
    name: "common.slice",
    initialState,
    reducers: {
        setAuthentication(state, { payload }) {
            state.user = payload.user
            state.roles = payload.roles
        },
        clearUser(state) {
            state.user = null
        }
    }
})


export const { setAuthentication, clearUser } = slice.actions;
export default slice.reducer;