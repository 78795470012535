import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Stack,
    useBreakpointValue,
    useColorModeValue,
    useToast,
    VStack,
} from '@chakra-ui/react'
import * as React from 'react'
import { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useIsLoginQuery, useLoginMutation } from "../../services/api.service";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { clearUser, setAuthentication } from "../../store/reducers/common/common-store";
import { ToastService } from '../../services/toast.service';

const LoginPage = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const toast = new ToastService(useToast());
    const [login, status] = useLoginMutation();

    let { isLoading, isError } = status;

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },

        validationSchema: Yup.object({
            email: Yup.string().required('Username or email required'),
            password: Yup.string().required("Password required")
        }),

        onSubmit: async (values: any) => {
            try {
                let res = await login({ 'usr': values?.email, 'pwd': values?.password }).unwrap()

                if (res?.message == "Logged In") {
                    await dispatch(setAuthentication({user:res.message.user,roles:[]}))
                    navigate('/app', { replace: true })
                } else {
                    toast.setDescription('Invalid Credentials').showErrorToast();
                }
            } catch {
                toast.setDescription('Invalid Credentials').showErrorToast();
            }
        }
    })

    return (
        <VStack className={'pattern'} h={'100vh'} justifyContent={"center"}>
            <Stack spacing="6">
                <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                    <Heading size={'xl'}>
                        JB Connector
                    </Heading>
                </Stack>
            </Stack>
            <Stack spacing="6">
                <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                    <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
                        Log in to your account
                    </Heading>
                </Stack>
            </Stack>
            <Box
                w={'400px'}
                py={{ base: '0', sm: '8' }}
                px={{ base: '4', sm: '10' }}
                bg={"white"}
                boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
                borderRadius={{ base: 'none', sm: 'xl' }}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing="6">
                        <Stack spacing="5">

                            <FormControl isInvalid={Boolean(formik.errors.email)}>
                                <FormLabel htmlFor="email">Username</FormLabel>
                                <Input onChange={formik.handleChange} value={formik.values.email} name="email"
                                    type="text" />
                                {formik.errors.email && <FormErrorMessage>{formik.errors.email}</FormErrorMessage>}
                            </FormControl>

                            <FormControl isInvalid={Boolean(formik.errors.password)}>
                                <FormLabel htmlFor="email">Password</FormLabel>
                                <Input onChange={formik.handleChange} value={formik.values.password} name="password"
                                    type="password" />
                                {formik.errors.email && <FormErrorMessage>{formik.errors.password}</FormErrorMessage>}
                            </FormControl>

                        </Stack>
                        <Stack spacing="6" mt={8}>
                            <Button disabled={isLoading} isLoading={isLoading} type={'submit'} colorScheme='blue'>Sign in</Button>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </VStack>
    )
}

export default LoginPage
