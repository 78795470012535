import {
    Stack,
    Table,
    Tbody,
    Th,
    Thead,
    Tr,
    Text,
    Box,
    Input,
    HStack,
    IconButton,
    Button,
    VStack,
    Spinner
} from "@chakra-ui/react";
import _ from "lodash";
import React, { useEffect, useState } from 'react';
import TableTr from "./components/TableTr";
import search from "multi-search"

interface INormal {
    header: string | number,
    accessor: string | number,
    className?: string,
    customRenderer?: (value: any, item: any) => JSX.Element,
    disabled?: boolean
}

interface conditionMap {
    [key: string | number]: string | number
}

interface IMedium {
    header: string | number,
    accessor: string | number,
    conditions: conditionMap,
    className?: string,
    customRenderer?: (value: any, item: any) => JSX.Element,
    disabled?: boolean
}

interface IActionProps {
    label: string | number,
    action: (line: { [key: string | number]: string | number }) => void
}

interface IActions {
    header: string | number,
    options: Array<IActionProps>,
    className?: string
}

type Creatable = {
    text?: string | undefined,
    onClick: any,
    isLoading?: boolean
}

interface IProps {
    title?: string,
    columns: Array<INormal | IMedium | IActions>,
    data: Array<{ [key: string | number]: string | number }>,
    onSelected: (line: { [key: string | number]: string | number }) => void,
    disablePointer?: boolean,
    outerPadding?: boolean,
    header?: boolean,
    searchable?: boolean,
    creatable?: Creatable,
    customFilterRenderer?: any,
    size?: 'sm' | 'md',
    height?: number,
    isLoading?: boolean
}


const ChakraTable = ({ size = 'md', isLoading = false, height, title, creatable, customFilterRenderer, searchable = true, header = false, columns, data, onSelected, disablePointer, outerPadding = true }: IProps) => {
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        setTableData(data as any)
    }, [JSON.stringify(data)])

    function selectedRow(row: any) {
        onSelected(row)
    }

    const searchHandler = (e: any) => {
        let filtered = search(data, e.target.value)
        setTableData(filtered as any)
    }

    return (
        <>
            <Stack bg={"white"} borderRadius={"md"} mx={outerPadding ? 3 : 0}>
                {
                    header ? (
                        <HStack mx={3} p={3} pt={5} justifyContent={"space-between"} bg={'white'} rounded={'md'}>
                            {title ? <Text fontSize={size == 'sm' ? 'lg' : 'xl'} fontWeight={"semibold"}>{title}</Text> : null}
                            <HStack>
                                {customFilterRenderer ? customFilterRenderer : null}
                                {searchable ? <Input size={size} onChange={searchHandler} width={250} placeholder={"Search Item..."} borderRadius='md' type='text' /> : null}
                                {!_.isEmpty(creatable) ? (
                                    <Button size={size} aria-label="Add new" isDisabled={creatable?.isLoading} onClick={creatable?.onClick} colorScheme='blue' color={'white'}>
                                        <HStack alignItems={"center"}>
                                            <Text fontSize={"xl"} fontWeight={"extrabold"}>+</Text>
                                            {creatable?.text ? <Text>{creatable.text}</Text> : null}
                                        </HStack>
                                    </Button>
                                ) : null}
                            </HStack>
                        </HStack>
                    ) : null
                }

                {header ? <hr></hr> : null}

                <VStack overflowY={"auto"} h={height ? height : undefined}>
                    <Table size={size} className="rounded-md" style={{ marginTop: header ? '0px' : '0px !important' }} variant='simple'>
                        <Thead>
                            <Tr>
                                {columns?.map((item: any, index: any) => {
                                    if (!item?.disabled) {
                                        return (
                                            <Th
                                                textAlign={item?.header == "Actions" ? "center" : "left"} py={2}
                                                key={index}>{item.header}
                                            </Th>
                                        )
                                    }
                                })}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {tableData?.map((item: any, index: any) => <TableTr disablePointer={disablePointer} onSelected={(selectedItem: any) => {
                                selectedRow(selectedItem)
                            }} key={index} columns={columns} item={item} />)}
                        </Tbody>
                    </Table>
                </VStack>
                {(isLoading) ? (
                    <Box className="flex w-full items-center justify-center h-20">
                        <Spinner size={"sm"} />
                    </Box>
                ) : null}
                {(_.isEmpty(data) && !isLoading) ? <Text pb={3} w={'full'} fontWeight={'bold'} textAlign={'center'}>No Data</Text> : null}
            </Stack>
        </>
    )
}

export default ChakraTable