import _ from 'lodash'
import * as Yup from "yup";
import ChakraModal from "../../components/chakra-modal/ChakraModal"
import { useFormik } from "formik"
import { FormInput } from "../../components/form-controllers/FormInput";
import { useParams } from "react-router-dom";
import { ToastService } from '../../services/toast.service'
import FormInputSelect from '../../components/form-controllers/FormInputSelect';
import { useToast, VStack } from "@chakra-ui/react"
import { PALLET, SHIPMENT } from "../../consts/methods.consts"
import { useEffect, useState } from "react";
import { useExecuteMutation, useMethodQuery } from "../../services/api.service"

interface ICreateNewShipmentModal {
    isOpen: any,
    onOpen: any,
    onClose: any,
    line?: any,
    shipment_id: any
}

const CreateNewItemAllocationModal = ({ isOpen, onClose, onOpen, line, shipment_id }: ICreateNewShipmentModal) => {
    const [create] = useExecuteMutation()
    const toast = new ToastService(useToast())
    const [errorMsgElement, setErrorMsgElement] = useState<any>(null)
    const { pallet_id } = useParams()

    const { data } = useMethodQuery({
        method: SHIPMENT.GET_BATCH_NUMBERS_OF_PALLETS_IN_A_SHIPMENT,
        body: {
            id: shipment_id
        }
    })

    const { data: expireDate } = useMethodQuery({
        method: SHIPMENT.GET_EXPIRE_DATES_OF_PALLETS_IN_A_SHIPMENT,
        body: {
            id: shipment_id
        }
    })

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            batch: Yup.string().required("Batch code is required"),
            mc_count: Yup.string().required("MC count is required"),
            quantity: Yup.number().max(_.get(line, "remaining_quantity")).min(1).required("Quantity is required").typeError("Quantity should be a number!"),
            expire_date: Yup.string()
                .required("Expire Date is required!")
                .matches(
                    /^\d{4}-\d{2}-\d{2}$/,
                    "Expire Date should be in the format YYYY-MM-DD"
                ),
        }),
        onSubmit: async (values: any) => {

            try {
                await create({
                    method: PALLET.CREATE_ITEM_ALLOCATION,
                    body: {
                        pallet_id,
                        ...values,
                        po_number: _.get(line, "po_number"),
                        item: _.get(line, 'item_ref_code'),
                        expire_date: _.get(values, 'expire_date')
                    }
                }).unwrap();

                toast.setDescription("Item allocation is created successfully").showSuccessToast()
                formik.resetForm()

                onClose();
            } catch (e: any) {
                toast.setDescription("Unable to create item allocation").showErrorToast()
            }

        }
    })

    useEffect(() => {
        if (+_.get(formik, ["values", "quantity"]) > _.get(line, "remaining_quantity")) {
            setErrorMsgElement(<p className={"text-red-600 text-[15px]"}>Max : {_.get(line, "remaining_quantity")}</p>)
        } else {
            setErrorMsgElement(<p className={"text-gray-400 text-[15px]"}>Max : {_.get(line, "remaining_quantity")}</p>)
        }
    }, [JSON.stringify(formik.values)])

    useEffect(() => {
        if (isOpen) {
            formik.resetForm();
            formik.setFieldValue("item", _.get(line, "item_code"))
        }
    }, [isOpen])

    return (
        <ChakraModal size={"xl"} primaryButtonText="Save"
            primaryAction={formik.submitForm} modalTitle="Item Allocation" isOpen={isOpen} onClose={onClose}>
            <VStack spacing={2}>
                <FormInput disableEvents type={"text"} formik={formik} name={"item"} label={"Item"} />
                <FormInputSelect isClearable={false} options={_.get(data, ['message'], [])} isRequired
                    label={"Batch"} formik={formik} name="batch" />
                <FormInput type={"number"} customMessage={errorMsgElement} formik={formik} name={"quantity"}
                    label={"Quantity"} />
                <FormInput formik={formik} name={"mc_count"} label={"MC Count"} />
                <FormInputSelect isClearable={true} options={_.get(expireDate, ['message'], [])} isRequired
                    label={"Expire Date"} formik={formik} name="expire_date" placeholder={'2023-05-05'} />
            </VStack>
        </ChakraModal>
    )
}

export default CreateNewItemAllocationModal