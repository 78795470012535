import {useRoutes} from "react-router-dom";
import ExternalLayout from "../layouts/external/ExternalLayout";
import InternalLayout from "../layouts/internal/InternalLayout";
import DashboardPage from "../pages/dashboard/Dashboard";
import LoginPage from "../pages/login/Login";
import ShipmentListPage from "../pages/shipment/ShipmentListPage";
import ViewShipmentPage from "../pages/shipment/ViewShipmentPage";
import ViewPalletPage from "../pages/Pallet/ViewPalletPage";
import PoViewPage from "../pages/po/PoViewPage";

const RouterConfigs = () => {
    const element = useRoutes([
        {
            path: "/",
            element: <ExternalLayout/>,
            children: [
                {
                    index: true,
                    element: <LoginPage/>
                },
            ]
        },
        {
            path: "/app",
            element: <InternalLayout/>,
            children: [
                {index: true, element: <DashboardPage/>},
                {
                    path: '/app/purchase-order/:po_id',
                    element: <PoViewPage/>,
                },
                {
                    path: '/app/shipments',
                    element: <ShipmentListPage/>,
                },
                {
                    path: '/app/shipment/:shipment_id',
                    element: <ViewShipmentPage/>
                },
                {
                    path: '/app/shipment/:shipment_id/pallet/:pallet_id',
                    element: <ViewPalletPage/>
                }
            ],
        },
        {path: "*", element: <h1>page not found</h1>},
    ]);

    return element;
};

export default RouterConfigs;