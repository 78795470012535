import _ from "lodash";
import { PO } from "../../../../consts/methods.consts";
import ChakraTable from "../../../../components/chakra-table/ChakraTable";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormInput } from "../../../../components/form-controllers/FormInput";
import { useMethodQuery } from "../../../../services/api.service";
import { Box, Grid, GridItem, VStack } from "@chakra-ui/react";

const OrderGeneralTab = () => {

    const { po_id: id } = useParams()

    const COLUMN = [
        {
            header: "Item Code",
            accessor: "item_code",
        },
        {
            header: "Item Ref Code",
            accessor: "item_ref_code",
        },
        {
            header: "Item Name",
            accessor: "item_name",
        },
        {
            header: "Quantity",
            accessor: "quantity"
        }
    ]

    const { data, isFetching } = useMethodQuery({
        method: PO.GET_PO,
        body: {
            id
        }
    })

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        formik.setFieldValue('po_number', _.get(data, ['message', 'po_number']));
        formik.setFieldValue('order_date', _.get(data, ['message', 'order_date']));
        formik.setFieldValue('delivery_date', _.get(data, ['message', 'delivery_date']));
    }, [JSON.stringify(data?.message)])

    return (
        <>
            <Box rounded={'md'} p={3} mx={3} mb={5} bg="white">
                <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                    <GridItem>
                        <VStack spacing={2}>
                            <FormInput disableEvents label="PO Number" formik={formik} name="po_number" />

                        </VStack>
                    </GridItem>
                    <GridItem>
                        <VStack spacing={2} justifyContent={"flex-end"} h={'full'}>
                            <FormInput disableEvents label="Order Date" formik={formik} name="order_date" />
                        </VStack>
                    </GridItem>
                </Grid>
            </Box>

            <ChakraTable
                title="Order Lines"
                header
                size={"sm"}
                isLoading={isFetching}
                onSelected={(line: any) => {
                }}
                columns={COLUMN}
                data={_.get(data, ['message', 'order_lines'], [])}
            />
        </>
    )
}

export default OrderGeneralTab