import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../consts/app.consts'

export const apiService = createApi({
    reducerPath: 'apiService',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        credentials: 'include'
    }),
    tagTypes: ["list"],
    endpoints: build => ({
        login: build.mutation<any, any>({
            query: (payload: any) => ({
                url: `login`,
                method: 'POST',
                body: payload,
            }),
        }),
        graphQl: build.query<any, any>({
            query: (payload: any) => ({
                url: `graphql`,
                method: 'POST',
                body: payload,
            }),
            providesTags: ["list"]
        }),
        execute: build.mutation<any, any>({
            query: ({ method, body }: any) => ({
                url: method,
                method: 'POST',
                body
            }),
            invalidatesTags: ["list"]
        }),
        method: build.query<any, any>({
            query: ({ method, body = {} }: any) => ({
                url: method,
                method: 'POST',
                body
            }),
            providesTags: ["list"]
        }),
        isLogin: build.query<any, any>({
            query: () => ({
                url: `jb_connector.api.user.current_user`,
                method: 'GET',
            }),
            forceRefetch: () => {
                return true
            }
        }),
        logout: build.mutation<any, any>({
            query: () => ({
                url: `logout`,
                method: 'POST',
            }),
        }),
    })
})

export const { useLoginMutation, useIsLoginQuery, useMethodQuery, useLogoutMutation, useGraphQlQuery, useExecuteMutation } = apiService
export default apiService