import { FormControl, FormLabel, Select, FormErrorMessage } from "@chakra-ui/react"
import _ from 'lodash'

interface FormSelectProps {
    formik: any,
    name: string,
    isRequired?: boolean,
    label?: string,
    options?: any,
    customMessage?: any
    keyPair?: {
        key: string,
        text: string
    },
    placeholder?: string | undefined
}

export const FormSelect = ({ formik, placeholder = "", customMessage = '', name, label, options = [{}], keyPair = { key: 'key', text: 'text' }, isRequired = false }: FormSelectProps) => {

    const changeHandler = (e: any) => {
        formik.setFieldValue(name, e.target.value)
    }

    const optionMarkup = options?.map((line: any, index: any) => {
        return (
            <option key={index} value={line[keyPair!.key]}>{line[keyPair!.text]}</option>
        )
    })

    return (
        <FormControl isRequired={isRequired} isInvalid={Boolean(formik.submitCount && formik.errors[name])}>
            <FormLabel>{label}</FormLabel>
            <Select placeholder={placeholder ? placeholder : 'Select option'} onChange={changeHandler} name={name} className="basic-single" borderRadius={'md'} w={'full'} borderWidth={'1px'}>
                <option hidden>{label}</option>
                {!_.isEmpty(options) ? optionMarkup : null}
            </Select>
            {(formik.submitCount && formik.errors[name]) ? <FormErrorMessage>{formik.errors[name]}</FormErrorMessage> : customMessage && customMessage}
        </FormControl>
    )
}