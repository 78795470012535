import { FormControl, FormLabel, Input, FormErrorMessage, Textarea } from "@chakra-ui/react"
import _ from "lodash";
import { useEffect, useState } from "react";

interface FormInputProps {
    classNames?: string,
    formik?: any,
    name: string,
    label?: string,
    type?: 'text' | 'email' | 'number' | 'date' | "datetime-local",
    variant?: 'input' | 'textArea',
    isRequired?: boolean,
    isDisabled?: boolean,
    defaultValue?: string | number,
    disableEvents?: boolean,
    customMessage?: any,
}

export const FormInput = ({ isDisabled = false, customMessage, disableEvents = false, defaultValue = '', formik, isRequired = false, name, label, type = 'text', variant = 'input', classNames = '' }: FormInputProps) => {
    const [df, setDf] = useState<any>('');

    useEffect(() => {
        if (defaultValue || defaultValue == 0) {
            setDf(defaultValue)
        }
    }, [defaultValue])

    return (
        <FormControl isRequired={isRequired} className={classNames} isInvalid={Boolean(formik.submitCount && formik.errors[name])}>
            {label && <FormLabel>{label}</FormLabel>}
            {variant === 'input' ? (
                <Input pointerEvents={disableEvents ? 'none' : undefined} isDisabled={isDisabled} value={_.isEmpty(formik.values) ? '' : formik.values[name]} name={name} onChange={formik.handleChange} type={type} rounded="md" />
            ) : (
                <Textarea pointerEvents={disableEvents ? 'none' : undefined} isDisabled={isDisabled} value={_.isEmpty(formik.values) ? '' : formik.values[name]} name={name} onChange={formik.handleChange} rounded="md" />
            )}
            {(formik.submitCount && formik.errors[name]) ? <FormErrorMessage>{formik.errors[name]}</FormErrorMessage> : customMessage && customMessage}
        </FormControl>
    )
}