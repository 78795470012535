import * as React from "react"
import {ChakraProvider, theme,} from "@chakra-ui/react"
import RouterConfigs from "./routes/route-configs";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store/store";
import '../src/index.css'

export const App = () => (
    <Provider store={store}>
        <ChakraProvider theme={theme}>
            <BrowserRouter>
                <RouterConfigs/>
            </BrowserRouter>
        </ChakraProvider>
    </Provider>
)

