import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";

interface IProps {
    isOpen: boolean,
    onClose: any,
    text: string,
    primaryAction?: any,
    primaryButtonText?: string
    form?: string,
    size?: string,
    primaryActionButtonType?: 'button' | 'submit' | 'reset',
    isLoading?: boolean,
    disablePrimaryButton?: boolean,
    secondaryButtonText?: string
}

const ConfirmationModal = ({ isOpen = false, secondaryButtonText = 'Cancel', onClose, text, form, primaryActionButtonType = 'submit', primaryButtonText = "Create", primaryAction, size = 'md', isLoading = false, disablePrimaryButton = false }: IProps) => {
    return (
        <>
            <Modal
                closeOnOverlayClick={!isLoading}
                isOpen={isOpen}
                motionPreset='slideInBottom'
                onClose={onClose}
                size={size}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirmation Required</ModalHeader>
                    <ModalBody pb={6}>
                        <p>{text}</p>
                    </ModalBody>

                    <ModalFooter>
                        <Button autoFocus={false} disabled={disablePrimaryButton || isLoading} isLoading={isLoading} type={primaryActionButtonType} form={form} onClick={primaryAction} colorScheme='blue' mr={3}>
                            {primaryButtonText}
                        </Button>
                        <Button autoFocus={false} colorScheme={"red"} onClick={() => onClose(false)}>{secondaryButtonText}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ConfirmationModal