import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast, VStack } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { FormInput } from "../../../components/form-controllers/FormInput";
import { FormSelect } from '../../../components/form-controllers/FormSelect';
import { SHIPMENT } from "../../../consts/methods.consts";
import { useExecuteMutation } from "../../../services/api.service";
import { ToastService } from '../../../services/toast.service';

interface IProps {
    isOpen: boolean,
    onClose: any
}

const reasons = [
    {
        key: "Reason 01",
        text: "Reason 01"
    },
    {
        key: "Reason 02",
        text: "Reason 02"
    },
    {
        key: "Reason 03",
        text: "Reason 03"
    },
    {
        key: "Reason 04",
        text: "Reason 04"
    },
    {
        key: "Other Reason",
        text: "Other Reason"
    },
]

const ShipmentCancellationModal = ({ isOpen = false, onClose }: IProps) => {
    const [create] = useExecuteMutation()
    const toast = new ToastService(useToast())
    const { shipment_id } = useParams()

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            cancellation_reason: Yup.string().required("Reason is required!"),
            custom_cancellation_reason: Yup
                .string()
                .when('cancellation_reason', {
                    is: 'Other Reason',
                    then: Yup.string().required('Custom cancellation reason is required'),
                }),
        }),
        onSubmit: async (values: any) => {
            try {
                await create({
                    method: SHIPMENT.CANCEL_SHIPMENT,
                    body: {
                        id: shipment_id,
                        cancellation_reason: _.get(values, 'custom_cancellation_reason') ? _.get(values, 'custom_cancellation_reason') : _.get(values, 'cancellation_reason')
                    }
                }).unwrap();

                toast.setDescription("Shipment cancelled successfully").showSuccessToast()
                formik.resetForm()

                onClose();
            } catch (e: any) {
                toast.setDescription("Unable to cancel shipment " + e).showErrorToast()
            }
        }
    })

    return (
        <>
            <Modal
                closeOnOverlayClick={false}
                isOpen={isOpen}
                motionPreset='slideInBottom'
                onClose={onClose}
                size={"md"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirmation Required</ModalHeader>
                    <ModalBody pb={6}>
                        <p className="text-justify pb-3">
                            Are you certain you wish to cancel the shipment? Please note that by doing so, all the pallets will be permanently deleted and cannot be recovered.
                        </p>
                        <FormSelect placeholder='Select a Reason' options={reasons} isRequired formik={formik} name="cancellation_reason"
                            label="Select a Reason" />
                        {
                            _.get(formik, ['values', 'cancellation_reason'], '') == 'Other Reason' && <VStack py={3}>
                                <FormInput isRequired type={"text"} variant={"textArea"} formik={formik} name={"custom_cancellation_reason"} label={"Other Reason"} />
                            </VStack>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={formik.submitForm} autoFocus={false} colorScheme='blue' mr={3}>
                            Yes, Cancel
                        </Button>
                        <Button autoFocus={false} colorScheme={"red"} onClick={() => onClose(false)}>
                            No
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ShipmentCancellationModal