import _ from 'lodash';
import axios from 'axios';
import React from 'react'
import { Spinner } from '@chakra-ui/react'
import { getBase64 } from '../../utils/utils';
import { IoMdAttach } from 'react-icons/io'
import { ATTACHMENT } from '../../consts/methods.consts';
import { SimpleGrid } from '@chakra-ui/react'
import { ToastService } from '../../services/toast.service';
import { FILE_UPLOAD_URL } from '../../consts/app.consts';
import ConfirmationModal from '../confirmation-modal/ConfirmationModal';
import { Box, useToast, useDisclosure } from '@chakra-ui/react';
import { useExecuteMutation, useMethodQuery } from '../../services/api.service';
import { Dropzone, FileMosaic, FullScreen, ImagePreview } from '@dropzone-ui/react'

interface IAttachmentProps {
    doctype: string,
    ref_doctype: string | undefined,
    isDisabled?: boolean
}

const Attachments = ({ doctype, ref_doctype, isDisabled }: IAttachmentProps) => {

    const { onOpen, onClose, isOpen } = useDisclosure()
    const [files, setFiles] = React.useState([]);
    const [imageSrc, setImageSrc] = React.useState(undefined);
    const [isUploading, setIsUploading] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState(null)
    const [isFileDeleting, setIsFileDeleting] = React.useState(false);
    const [mutate] = useExecuteMutation()
    const toast = new ToastService(useToast())

    const { data, refetch } = useMethodQuery({
        method: ATTACHMENT.GET_ATTACHMENTS,
        body: {
            doctype,
            ref_doctype
        }
    })

    const attachmentList = _.get(data, "message", [])

    const updateFiles = async (incomingFiles: any) => {
        if (!_.isEmpty(incomingFiles) && incomingFiles.length > 0) {
            setIsUploading(true);
            setSelectedFile(null);
            setIsFileDeleting(false);
            incomingFiles.forEach((f: any) => {
                getBase64(incomingFiles[0]["file"], async (result: any) => {
                    axios.post(FILE_UPLOAD_URL, {
                        cmd: "uploadfile",
                        doctype,
                        docname: ref_doctype,
                        filename: _.get(f, "name"),
                        filedata: result,
                        from_form: '1'
                    },
                        { withCredentials: true }
                    ).then((res: any) => {
                        refetch()
                        setFiles([])
                        toast.setDescription("File uploaded successfully").showSuccessToast()
                    }).finally(() => {
                        setIsUploading(false);
                    })
                });
            });
        }

        setFiles(incomingFiles);
    };

    const removeFile = (id: any) => {
        setFiles(files.filter((x: any) => x.id !== id));
    };

    const handleSee = (imageSource: any) => {
        setImageSrc(imageSource);
    };

    const onDeleteAttachment = async () => {
        try {

            if (!_.get(selectedFile, "name")) throw new Error();

            setIsFileDeleting(true)

            await mutate({
                method: ATTACHMENT.DELETE_ATTACHMENT,
                body: { id: _.get(selectedFile, "name") }
            }).unwrap();

            toast.setDescription("File deleted successfully").showSuccessToast()
        } catch (e: any) {
            toast.setDescription("Sorry, Unable to delete file!").showErrorToast()
        } finally {
            setSelectedFile(null);
            setIsFileDeleting(false);
            onClose()
        }
    }

    const attachmentsMarkup = attachmentList.map((attachment: any) => {
        return (
            <div className='flex gap-3 justify-start items-start mb-3'>
                <a href={attachment.file_url} target='_blank'>
                    <IoMdAttach className='text-2xl opacity-40 hover:opacity-100 cursor-pointer' /></a>
                <div>
                    <a href={attachment.file_url} target='_blank'>
                        <p className='font-bold cursor-pointer'>{attachment.file_name}</p>
                        <p className='cursor-pointer'>{attachment.creation}</p></a>
                    <button onClick={() => {
                        setSelectedFile(attachment);
                        onOpen();
                    }} disabled={isDisabled} className='cursor-pointer disabled:cursor-default disabled:opacity-50 text-red-400'>Delete</button>
                </div>
            </div>
        )
    })

    return (
        <>
            <ConfirmationModal isLoading={isFileDeleting} isOpen={isOpen} onClose={onClose} text="Are you certain you wish to delete this file? Please note that by doing so, this file will be permanently deleted and cannot be recovered" primaryButtonText="Delete" primaryAction={onDeleteAttachment} />

            {attachmentsMarkup.length == 0 ? (
                <SimpleGrid columns={1}>
                    <Dropzone
                        maxFileSize={20000000}
                        className='w-full'
                        disabled={isDisabled}
                        onChange={updateFiles}
                        value={files}>
                        {files.map((file: any) => {
                            return (<>{isUploading && (
                                <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                    size="xl"
                                    transform="translate(-50%, -50%)"
                                />
                            )}<FileMosaic onSee={handleSee} key={file.id} {...file} onDelete={removeFile} info preview /></>)

                        })}
                    </Dropzone>
                    <FullScreen
                        open={imageSrc !== undefined}
                        onClose={() => setImageSrc(undefined)}
                    >
                        <ImagePreview src={imageSrc} />
                    </FullScreen> </SimpleGrid>
            ) : (
                <SimpleGrid columns={2} spacing={4}>
                    <Box w='100%' h='full'>{attachmentsMarkup}</Box>
                    <Dropzone
                        maxFileSize={20000000}
                        className='w-full'
                        onChange={updateFiles}
                        disabled={isDisabled}
                        value={files}>
                        {files.map((file: any) => {
                            return (<>{isUploading && (
                                <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                    size="xl"
                                    transform="translate(-50%, -50%)"
                                />
                            )}<FileMosaic onSee={handleSee} key={file.id} {...file} onDelete={removeFile} info preview /></>)

                        })}
                    </Dropzone>
                    <FullScreen
                        open={imageSrc !== undefined}
                        onClose={() => setImageSrc(undefined)}>
                        <ImagePreview src={imageSrc} />
                    </FullScreen>
                </SimpleGrid>
            )}
        </>
    )
}

export default Attachments;