import _ from "lodash";
import React from 'react'
import { Select } from "@chakra-ui/react"
import { useState } from "react";
import PageHeader from "../../components/page-header/PageHeader";
import { useEffect } from "react";
import ChakraTable from "../../components/chakra-table/ChakraTable";
import { useParams } from "react-router-dom";
import { InnerLoading } from "../../components/inner-loading/InnerLoading";
import { ToastService } from "../../services/toast.service";
import { SHIPMENT_TYPES } from "../../consts/type.consts";
import { PALLET, SHIPMENT } from "../../consts/methods.consts";
import CreateNewItemAllocationModal from "./CreateNewItemAllocationModal";
import { useExecuteMutation, useMethodQuery } from "../../services/api.service";
import { Button, useDisclosure, useToast, Badge } from "@chakra-ui/react";

const ITEM_TBL_COLUMNS = [
    {
        header: "PO Ref",
        accessor: "po_number"
    },
    {
        header: "Item Code",
        accessor: "item_code",
    },
    {
        header: "Item Name",
        accessor: "item_name",
    },
    {
        header: "Total Quantity",
        accessor: "total_quantity",
    },
    {
        header: "Allocated Quantity",
        accessor: "allocated_quantity",
    },
    {
        header: "Remaining Quantity",
        accessor: "remaining_quantity"
    }
]


const palletTypes = [
    {
        key: "Standard",
        text: "Standard"
    },
    {
        key: "Loose Cartons",
        text: "Loose Cartons"
    }
]

const ViewPalletPage = () => {
    const [create] = useExecuteMutation()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = new ToastService(useToast())
    const { pallet_id, shipment_id } = useParams()
    const [action] = useExecuteMutation();
    const [selectedItem, setSelectedItem] = useState<any>({})
    const [isLoading, setIsLoading] = React.useState(true);

    const { data: items, isFetching } = useMethodQuery({
        method: PALLET.GET_ITEM_LIST_FOR_SHIPMENT,
        body: {
            shipment_id
        }
    })

    const { data: allocations, refetch } = useMethodQuery({
        method: PALLET.GET_PALLET_ALLOCATIONS,
        body: {
            pallet_id
        }
    })

    const { data: shipmentStatus } = useMethodQuery({
        method: SHIPMENT.GET_SHIPMENT_STATUS,
        body: {
            id: shipment_id
        }
    })

    const { data: palletType } = useMethodQuery({
        method: PALLET.GET_PALLET_TYPE,
        body: {
            pallet_id
        }
    })

    const status = _.get(shipmentStatus, 'message')
    const type = _.get(palletType, 'message')

    const ALLOCATION_TBL_COLUMNS = [
        {
            header: "Item Code",
            accessor: "item_code"
        },
        {
            header: "Item Name",
            accessor: "item_name"
        },
        {
            header: "Quantity",
            accessor: "quantity"
        },
        {
            header: "Batch",
            accessor: "batch",
        },
        {
            header: "Expire Date",
            accessor: "expire_date"
        },
        {
            header: "MC Count",
            accessor: "mc_count"
        },
        {
            header: "PO Ref",
            accessor: "po_number",
        },
        {
            header: "QR Printed",
            accessor: 'is_qr_printed',
            condition: {
                "NO": "red",
                "YES": "green"
            }
        },
        {
            header: "Print QR",
            accessor: 'print_qr',
            customRenderer: (value: any, item: any) => {
                return <button onClick={async () => {
                    const res = await create({
                        method: PALLET.PRINT_PALLET_ITEM_QR,
                        body: {
                            id: item.name,
                            shipment: shipment_id
                        }
                    }).unwrap()

                    var myWindow: any = window.open("", "MsgWindow", 'popup');
                    myWindow.document.write(res.message);

                    myWindow.document.close();
                    myWindow.focus();
                    myWindow.setTimeout(() => {
                        myWindow.print();
                        myWindow.close();
                    }, 1000);

                    refetch()
                }
                } className={"bg-blue-400 text-white cursor-pointer hover:bg-blue-500 px-2 py-1 rounded"}>Print</button>
            }
        },
        {
            header: "Actions",
            disabled: (status == SHIPMENT_TYPES.PROCEEDED_TO_SHIPPING || status == SHIPMENT_TYPES.DELAYED || status == SHIPMENT_TYPES.SHIPPED),
            options: [
                {
                    label: "Remove",
                    action: async function (line: any) {
                        try {
                            await action({
                                method: PALLET.REMOVE_ITEM_ALLOCATION,
                                body: {
                                    id: line.name
                                }
                            }).unwrap()

                            toast.setDescription("Item allocation removed").showSuccessToast()
                        } catch (e) {
                            toast.setDescription("Sorry, Unable to remove item allocation!").showErrorToast()
                        }
                    }
                }
            ]
        }
    ]

    useEffect(() => {
        if (!isFetching) {
            setIsLoading(false)
        }
    }, [isFetching])


    const changeHandler = async (e: any) => {
        try {
            await create({
                method: PALLET.UPDATE_PALLET_TYPE,
                body: {
                    pallet_id: pallet_id,
                    pallet_type: e.target.value
                }
            }).unwrap();

            toast.setDescription("Pallet type updated successfully").showSuccessToast()

        } catch (e: any) {
            toast.setDescription("Unable to update pallet type").showErrorToast()
        }
    }

    const optionMarkup = palletTypes?.map((line: any, index: any) => {
        return (
            <option key={index} value={line.key}>{line.text}</option>
        )
    })

    const actionRenderer = (
        <>
            {status !== SHIPMENT_TYPES.DELAYED && status !== SHIPMENT_TYPES.SHIPPED ?
                <Select defaultValue={type} name="pallet_type" onChange={changeHandler} className="!border-gray-300" borderRadius={'md'} w={'full'} h={"8"}>
                    <option hidden>{type}</option>
                    {!_.isEmpty(palletTypes) ? optionMarkup : null}
                </Select> : null}
            <Button size={"sm"} onClick={async () => {
                try {
                    const noQrList = _.filter(_.get(allocations, ["message"]), { "is_qr_printed": "NO" })

                    if (_.isEmpty(noQrList)) {
                        if (!_.isEmpty(_.get(allocations, ["message"]))) {
                            const res = await create({
                                method: PALLET.PRINT_PALLET_QR,
                                body: {
                                    id: pallet_id,
                                    shipment: shipment_id
                                }
                            }).unwrap()

                            var myWindow: any = window.open("", "MsgWindow", 'popup');
                            myWindow.document.write(res.message);

                            myWindow.document.close();
                            myWindow.focus();
                            myWindow.setTimeout(() => {
                                myWindow.print();
                                myWindow.close();
                            }, 1000);
                        } else {
                            toast.setTitle("Please pick the order").setDescription("No items in the pallet").showErrorToast()
                        }
                    } else {
                        toast.setTitle("Please print QR codes").setDescription("You have not printed QR codes for some allocations. Please print it and try again").showErrorToast()
                    }

                } catch (e: any) { }
            }} flexShrink={0} colorScheme="blue" fontWeight="bold">
                Print
            </Button>
        </>
    )

    const PalletTypeRenderer = () => {
        if (type == 'Standard') {
            return (
                <Badge colorScheme='yellow'>Standard</Badge>
            )
        }

        if (type == "Loose Cartons") {
            return (
                <Badge colorScheme='green'>Loose Cartons</Badge>
            )
        }
    }

    return (
        <>
            <PageHeader subTitleRenderer={PalletTypeRenderer()} actionRenderer={actionRenderer}
                backHandler primaryButton={false} title={`Pallet: ${pallet_id}`}
                subTitle={"Shipment"} buttonText={"Save"} />
            {isLoading ?
                <InnerLoading /> :
                <div className={"grid grid-cols-1"}>
                    {
                        !_.isEmpty(_.get(items, ["message"])) ? (
                            <ChakraTable
                                size={"sm"}
                                title="Items"
                                height={(window.innerHeight / 2) - 150}
                                header
                                onSelected={(line: any) => {
                                    onOpen()
                                    setSelectedItem(line)
                                }}
                                columns={ITEM_TBL_COLUMNS}
                                data={_.get(items, ["message"])}
                            />
                        ) : null
                    }

                    {!_.isEmpty(_.get(items, ["message"])) && <div className={"mt-7"}></div>}

                    <ChakraTable
                        size={"sm"}
                        title="Allocations"
                        header
                        isLoading={isFetching}
                        onSelected={(line: any) => {
                        }}
                        columns={ALLOCATION_TBL_COLUMNS}
                        data={_.get(allocations, ["message"])}
                    />
                    <CreateNewItemAllocationModal line={selectedItem} isOpen={isOpen} onOpen={onOpen} onClose={onClose} shipment_id={shipment_id} />
                </div>}
        </>
    )
}

export default ViewPalletPage;