export class ToastService {
    private title;
    private description;
    private toast;

    constructor(toast: any) {
        this.toast = toast;
        this.title = '';
        this.description = ''
    }

    public setTitle(title: string) {
        this.title = title;

        return this;
    }

    public setDescription(desc: string) {
        this.description = desc;

        return this;
    }

    public showErrorToast() {
        this.toast({
            title: this.title ? this.title : 'Error',
            description: this.description,
            position: "bottom",
            status: 'error',
            duration: 2000,
            isClosable: true,
        })
    }

    public showSuccessToast() {
        this.toast({
            title: this.title ? this.title : 'Success',
            description: this.description,
            position: "bottom",
            status: 'success',
            duration: 2000,
            isClosable: true,
        })
    }
}