import _ from "lodash";
import {
    Input,
    Editable,
    EditableInput,
    EditablePreview,
    useEditableControls,
    ButtonGroup,
    Flex,
    useToast
} from "@chakra-ui/react"
import React from 'react'
import { useEffect, useState } from "react";
import { IconButton } from '@chakra-ui/react'
import { SHIPMENT } from "../../consts/methods.consts";
import { useExecuteMutation } from "../../services/api.service";
import { ToastService } from "../../services/toast.service";
import { AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineEdit } from "react-icons/ai"

interface UpdatableFormFieldProps {
    id?: any,
    value?: string,
    name: string,
    label?: string,
    isDisabled?: boolean
}

const UpdatableFormField = ({ name, label, value, id ,isDisabled = false}: UpdatableFormFieldProps) => {
    const [create] = useExecuteMutation()
    const toast: ToastService = new ToastService(useToast());
    const [date, setDate] = useState<any>();
    const [editing, setIsEditing] = React.useState(false);

    useEffect(() => {
        if (value) {
            setDate(value)
        }
    }, [value])

    useEffect(() => {
        if (date) {
            setIsEditing(true)
        }
    }, [date])

    const submitHandler = async () => {

        if (editing) {
            try {
                await create({
                    method: SHIPMENT.UPDATE_SHIPMENT_DATES,
                    body: {
                        name: id,
                        field: name,
                        value: date
                    }
                }).unwrap();

                toast.setDescription("Date updated successfully").showSuccessToast()

            } catch {
                toast.setDescription("Unable to update date").showErrorToast()
            }
        }
    }

    const onChangeHandler = (e: any) => {
        setDate(e.target.value);
    }

    return (
        <>
            <Editable
                textAlign='start'
                fontSize='sm'
                className="!w-full"
                value={date}
                onSubmit={submitHandler}
                isDisabled={isDisabled}
            >
                <label className="text-sm">{label}</label>
                <Flex justifyContent='center' gap={2} paddingTop={2}>
                    <EditablePreview className="!border w-full px-4"/>
                    <Input as={EditableInput} type='date' value={date}  size='sm' onChange={onChangeHandler}/>
                    <EditableComponent isDisabled={isDisabled}/>
                </Flex>
            </Editable>
        </>
    )
}

interface EditableProps {
    isDisabled?: boolean
}

const EditableComponent = ({isDisabled=false}:EditableProps) => {
    const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } = useEditableControls()

    return isEditing ? (
        <ButtonGroup justifyContent='center' size='sm' >
            <IconButton aria-label='Done'  icon={<AiOutlineCheckCircle className="text-green-700" />} {...getSubmitButtonProps()} />
            <IconButton aria-label='' icon={<AiOutlineCloseCircle className="text-red-700" />} {...getCancelButtonProps()} />
        </ButtonGroup>
    ) : (
        <Flex justifyContent='center' >
            <IconButton aria-label='' isDisabled={isDisabled} size='sm' icon={<AiOutlineEdit />} {...getEditButtonProps()} />
        </Flex>
    )
}

export default UpdatableFormField