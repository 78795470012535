import _ from "lodash"
import { SHIPMENT } from "../../consts/methods.consts"
import PageHeader from "../../components/page-header/PageHeader"
import ChakraTable from "../../components/chakra-table/ChakraTable"
import { useNavigate } from "react-router-dom"
import { useDisclosure } from "@chakra-ui/react"
import { useMethodQuery } from "../../services/api.service"
import { useEffect, useState } from "react"
import CreateNewShipmentModal from "./components/CreateNewShipmentModal"

const COLUMN = [
    {
        header: "# ID",
        accessor: "name",
    },
    {
        header: "Date",
        accessor: "created_date",
    },

    {
        header: "State",
        accessor: "is_short_shipment",
        condition: {
            'Short Shipment': "blue",
            'Standard Shipment': "green"
        }
    }, {
        header: "Status",
        accessor: "status",
        condition: {
            'Proceeded to shipping': "blue",
            Open: "pink",
            Cancelled: "red",
            Shipped: "green",
            Delayed: "orange",
            Processing: "yellow"
        }
    },
]

const ShipmentListPage = () => {
    const navigate = useNavigate()
    const [columnData, setColumnData] = useState([])
    const { isOpen, onOpen, onClose } = useDisclosure()

    const { data, isFetching } = useMethodQuery({
        method: SHIPMENT.GET_SHIPMENT_LIST
    })

    useEffect(() => {
        setColumnData(_.get(data, 'message', []))
    }, [JSON.stringify(data?.message)])

    const createNewShipment = () => {
        onOpen()
    }

    const onTableLineClick = (line: any) => {
        navigate({
            pathname: `/app/shipment/${line.name}`
        })
    }

    return (
        <>
            <PageHeader
                onPress={createNewShipment}
                title={"Shipment List"}
                subTitle={"Shipment"}
                buttonText={"Plan a new shipment"} />
            <ChakraTable
                onSelected={onTableLineClick}
                columns={COLUMN}
                size={"sm"}
                header
                data={columnData}
                isLoading={isFetching}
            />
            <CreateNewShipmentModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
        </>
    )
}

export default ShipmentListPage