import UpdatableFormField from "../../../../components/form-controllers/UpdatableFormField";
import { SHIPMENT } from "../../../../consts/methods.consts";
import { useParams } from "react-router-dom";
import { Box, GridItem, SimpleGrid } from "@chakra-ui/react";
import { useMethodQuery } from "../../../../services/api.service";
import _ from 'lodash'
import { useSelector } from "react-redux";

interface IInformationTabProps {
    status: any,
}

const InformationTab = ({ status }: IInformationTabProps) => {
    const { shipment_id: id } = useParams();
    const roles = useSelector((state: any) => state.common.roles || '')

    const { data } = useMethodQuery({
        method: SHIPMENT.GET_SHIPMENT_DETAILS,
        body: {
            id
        }
    })

    const isCancelled = (status == 'Cancelled')
    const isJBUser = roles.includes('JB User');
    const isTeaDropUser = roles.includes('Teadrop User');
    const DisableJBFields = (!isJBUser || isCancelled)
    const DisableTeaDropFields = (!isTeaDropUser || isCancelled)

    return (
        <>
            <Box rounded={'md'} mx={3} >
                <SimpleGrid columns={2} gap={3} >
                    <GridItem bg="white" p={3} borderRadius={"lg"} >
                        <p className="font-bold text-lg mb-2">JB Shipping Information</p>
                        <div className="grid grid-cols-2 gap-3">
                            <UpdatableFormField label='PO Draft Date' isDisabled={DisableJBFields} name='jb_po_draft_date' value={_.get(data, ['message', 'jb_po_draft_date'])} id={id} />
                            <UpdatableFormField label='Pallet Calculation Received Date' isDisabled={DisableJBFields} name='jb_pallet_calculation_received_date' value={_.get(data, ['message', 'jb_pallet_calculation_received_date'])} id={id} />
                            <UpdatableFormField label='PO Finalize Date' isDisabled={DisableJBFields} name='jb_po_finalize_date' value={_.get(data, ['message', 'jb_po_finalize_date'])} id={id} />
                            <UpdatableFormField label='GRD Confirmation Date' isDisabled={DisableJBFields} name='jb_grd_confirmation_date' value={_.get(data, ['message', 'jb_grd_confirmation_date'])} id={id} />
                            <UpdatableFormField label='PO Confirmation Date' isDisabled={DisableJBFields} name='jb_po_confirmation_date' value={_.get(data, ['message', 'jb_po_confirmation_date'])} id={id} />
                            <UpdatableFormField label='SC Received Date' isDisabled={DisableJBFields} name='jb_sc_received_date' value={_.get(data, ['message', 'jb_sc_received_date'])} id={id} />
                            <UpdatableFormField label='Sign Contract Sent Date' isDisabled={DisableJBFields} name='jb_sign_contract_sent_date' value={_.get(data, ['message', 'jb_sign_contract_sent_date'])} id={id} />
                            <UpdatableFormField label='Shipment Advice Received Date' isDisabled={DisableJBFields} name='jb_shipment_advice_received_date' value={_.get(data, ['message', 'jb_shipment_advice_received_date'])} id={id} />
                            <UpdatableFormField label='CA & Shipment Documents Sent Date' isDisabled={DisableJBFields} name='jb_ca_and_shipment_documents_sent_date' value={_.get(data, ['message', 'jb_ca_and_shipment_documents_sent_date'])} id={id} />
                            <UpdatableFormField label='Estimated Delivery Date' isDisabled={DisableJBFields} name='jb_estimated_delivery_date' value={_.get(data, ['message', 'jb_estimated_delivery_date'])} id={id} />
                        </div>
                    </GridItem>
                    <GridItem bg="white" p={3} borderRadius={"lg"} >
                        <p className="font-bold text-lg mb-2">Teadrop Estimation</p>
                        <div className="grid grid-cols-2 gap-3">
                            <UpdatableFormField label='PO Draft Date' isDisabled={DisableTeaDropFields} name='td_po_draft_date' value={_.get(data, ['message', 'td_po_draft_date'])} id={id} />
                            <UpdatableFormField label='Pallet Calculation Received Date' isDisabled={DisableTeaDropFields} name='td_pallet_calculation_received_date' value={_.get(data, ['message', 'td_pallet_calculation_received_date'])} id={id} />
                            <UpdatableFormField label='PO Finalize Date' isDisabled={DisableTeaDropFields} name='td_po_finalize_date' value={_.get(data, ['message', 'td_po_finalize_date'])} id={id} />
                            <UpdatableFormField label='GRD Confirmation Date' isDisabled={DisableTeaDropFields} name='td_grd_confirmation_date' value={_.get(data, ['message', 'td_grd_confirmation_date'])} id={id} />
                            <UpdatableFormField label='PO Confirmation Date' isDisabled={DisableTeaDropFields} name='td_po_confirmation_date' value={_.get(data, ['message', 'td_po_confirmation_date'])} id={id} />
                            <UpdatableFormField label='SC Received Date' isDisabled={DisableTeaDropFields} name='td_sc_received_date' value={_.get(data, ['message', 'td_sc_received_date'])} id={id} />
                            <UpdatableFormField label='Sign Contract Sent Date' isDisabled={DisableTeaDropFields} name='td_sign_contract_sent_date' value={_.get(data, ['message', 'td_sign_contract_sent_date'])} id={id} />
                            <UpdatableFormField label='Shipment Advice Received Date' isDisabled={DisableTeaDropFields} name='td_shipment_advice_received_date' value={_.get(data, ['message', 'td_shipment_advice_received_date'])} id={id} />
                            <UpdatableFormField label='CA & Shipment Documents Sent Date' isDisabled={DisableTeaDropFields} name='td_ca_and_shipment_documents_sent_date' value={_.get(data, ['message', 'td_ca_and_shipment_documents_sent_date'])} id={id} />
                            <UpdatableFormField label='Estimated Delivery Date' isDisabled={DisableTeaDropFields} name='td_estimated_delivery_date' value={_.get(data, ['message', 'td_estimated_delivery_date'])} id={id} />
                        </div>
                    </GridItem>
                </SimpleGrid>
            </Box></>
    )
}

export default InformationTab