import _ from "lodash";
import { PO } from "../../consts/methods.consts";
import { Box } from "@chakra-ui/react";
import PageHeader from "../../components/page-header/PageHeader";
import ChakraTable from "../../components/chakra-table/ChakraTable";
import { useNavigate } from "react-router-dom";
import { useMethodQuery } from '../../services/api.service';

const COLUMN = [
    {
        header: "PO Number",
        accessor: "name",
    },
    {
        header: "Order Date",
        accessor: "order_date",
    },
    {
        header: "Delivery Date",
        accessor: "delivery_date"
    },
    {
        header: "Status",
        accessor: "status",
        condition: {
            'Proceeded to shipping': "green",
            Open: "pink",
            Processing: "yellow"
        }
    }
]

const DashboardPage = () => {
    const navigate = useNavigate()
    const { data: poList, isFetching } = useMethodQuery({
        method: PO.GET_PO_LIST
    })

    const onTableLineClick = (line: any) => {
        navigate({
            pathname: `/app/purchase-order/${line.name}`
        })
    }

    return (
        <>
            <PageHeader primaryButton={false} title={'Orders'} subTitle={'Orders from TeaDrop'} />
            <Box >
                <ChakraTable
                    isLoading={isFetching}
                    searchable={false}
                    size={"sm"}
                    onSelected={onTableLineClick}
                    columns={COLUMN}
                    data={_.get(poList, 'message', [])}
                />
            </Box>
        </>

    )
}

export default DashboardPage
