import { useNavigate } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";
import { Box, Button, Heading, HStack, Stack, Text, VStack } from "@chakra-ui/react";

interface IProps {
    title?: string,
    subTitle?: string,
    buttonText?: string,
    onPress?: any,
    primaryButton?: boolean,
    primaryButtonIsLoading?: boolean,
    disablePrimaryButton?: boolean,
    formId?: string,
    actionRenderer?: any,
    statusRenderer?: any,
    subTitleRenderer?: any,
    backHandler?: boolean
}

const PageHeader = ({
    title,
    subTitle,
    buttonText = "Button",
    primaryButton = true,
    onPress = () => { },
    primaryButtonIsLoading = false,
    disablePrimaryButton = false,
    formId,
    actionRenderer = undefined,
    statusRenderer = undefined,
    subTitleRenderer = undefined,
    backHandler = false
}: IProps) => {
    const navigate = useNavigate()

    return (
        <Box px={3} pb={5} mx="auto">
            <Stack
                spacing="5"
                direction={{ base: 'column', md: 'row' }}
                justify="space-between"
                align={{ base: 'flex-start', md: 'center' }}
            >
                <HStack>
                    {backHandler ? (
                        <div onClick={() => navigate(-1)} className="bg-white cursor-pointer hover:bg-gray-100 transition-all shadow-sm border rounded-lg h-[58px] w-[58px] flex justify-center items-center">
                            <IoArrowBackSharp className="text-[30px]" />
                        </div>
                    ) : null}
                    <VStack alignItems={"flex-start"}>
                        <HStack>
                            <Heading size="md">{title}</Heading>
                            {statusRenderer ? statusRenderer : null}
                        </HStack>
                        <Text color={'gray.400'} fontSize="sm">
                            {subTitleRenderer ? subTitleRenderer : subTitle}
                        </Text>
                    </VStack>
                </HStack>

                <Stack
                    direction={{ base: 'column', md: 'row' }}
                    spacing="4"
                    justify="flex-end"
                    flex="1"
                    w={{ base: 'full', md: 'auto' }}
                >
                    {(primaryButton && !actionRenderer) ? <Stack display="inline-flex" direction="row" spacing="4">
                        <Button disabled={disablePrimaryButton} form={formId ? formId : ''} isLoading={primaryButtonIsLoading} onClick={onPress} flexShrink={0} colorScheme="blue" fontWeight="bold">
                            {buttonText}
                        </Button>
                        {actionRenderer}
                    </Stack> : null}
                    {actionRenderer && <Stack display="inline-flex" direction="row" spacing="4">
                        {actionRenderer}
                    </Stack>}
                </Stack>
            </Stack>
        </Box>
    )
}

export default PageHeader