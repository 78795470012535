import _ from "lodash";
import { useState } from "react";
import ChakraTable from "../../../../components/chakra-table/ChakraTable";
import PoLinesDrawer from "../PoLinesDrawer";
import { useNavigate, useParams } from "react-router-dom";
import { PALLET, SHIPMENT } from "../../../../consts/methods.consts";
import { SHIPMENT_TYPES } from "../../../../consts/type.consts";
import { ToastService } from "../../../../services/toast.service";
import { useExecuteMutation, useMethodQuery } from "../../../../services/api.service";
import { HStack, Text, useDisclosure, useToast } from "@chakra-ui/react";

interface IGeneralTabProps {
    status: any,
}

const { PROCEEDED_TO_SHIPPING, CANCELLED, SHIPPED, DELAYED } = SHIPMENT_TYPES

const GeneralTab = ({ status }: IGeneralTabProps) => {
    const [selectedPo, setSelectedPo] = useState<any>([])
    const { isOpen, onOpen, onClose } = useDisclosure()

    const navigate: React.Dispatch<any> = useNavigate()
    const [action, { isLoading }] = useExecuteMutation()
    const { shipment_id: id } = useParams();

    const toast: ToastService = new ToastService(useToast());

    const createANewPallet = async () => {
        try {
            await action({
                method: PALLET.NEW_PALLET,
                body: {
                    id
                }
            }).unwrap();

            toast.setDescription("Pallet is created successfully").showSuccessToast();
        } catch (e) {
            toast.setDescription("Unable to create pallet!").showErrorToast();
        }
    }

    const { data, isFetching: isFetching } = useMethodQuery({
        method: SHIPMENT.GET_PO_LIST_BY_SHIPMENT,
        body: {
            id
        }
    })

    const { data: shipment } = useMethodQuery({
        method: SHIPMENT.GET_SHIPMENT_STATUS,
        body: {
            id
        }
    })

    const { data: palletList, isFetching: isPalletCreationFetching } = useMethodQuery({
        method: PALLET.GET_PALLET_LIST,
        body: {
            id
        }
    })

    const onPalletTableLineClick = (line: any) => {
        navigate({
            pathname: `/app/shipment/${id}/pallet/${line.name}`
        })
    }

    const onPoLineCLick = (line: any) => {
        setSelectedPo(line);
        onOpen();
    }

    const PO_TAB_COLUMN = [
        {
            header: "PO Number",
            accessor: "name",
        },
        {
            header: "Order Date",
            accessor: "order_date",
        },
        {
            header: "Delivery Date",
            accessor: "delivery_date"
        },
        {
            header: "Picking Status",
            accessor: "invalid",
            customRenderer: (value: any, item: any) => {
                return (
                    <HStack style={{ width: '50%' }} alignItems={"center"}>
                        <p className="text-xs font-semibold text-blue-[#0064b4]">
                            {((_.get(item, 'picked') / _.get(item, 'total')) * 100).toFixed(0)}%
                        </p>
                        <div className="w-full bg-gray-200 rounded-full h-2.5" style={{ width: `${item.total }%` }}>
                            <div className="bg-green-600 h-2.5 rounded-full" style={{ width: `${(item.picked / item.total) * 100}%` }} ></div>
                        </div>
                    </HStack >
                )
            }
        }
    ]

    const PALLET_TAB_COLUMNS = [
        {
            header: "Pallet Id",
            accessor: "name"
        },
        {
            header: "Created Date",
            accessor: "creation"
        },
        {
            header: "QR Printed",
            accessor: 'is_qr_printed',
            condition: {
                "NO": "red",
                "YES": "green"
            }
        },
        {
            header: "Actions",
            disabled: (status == PROCEEDED_TO_SHIPPING || status == SHIPPED || status == DELAYED),
            options: [
                {
                    label: "Remove",
                    action: async function (line: any) {
                        try {
                            await action({
                                method: PALLET.REMOVE_PALLET,
                                body: {
                                    id: line.name
                                }
                            }).unwrap()

                            toast.setDescription("Pallet removed").showSuccessToast()
                        } catch (e) {
                            toast.setDescription("Sorry, Unable to remove pallet!").showErrorToast()
                        }
                    }
                }
            ]
        }
    ]

    return (
        <>
            <Text pb={2} px={3} fontWeight={"semibold"}>Allocated purchase orders</Text>
            <ChakraTable
                size={"sm"}
                title="Purchase Order List"
                disablePointer={status == CANCELLED}
                onSelected={onPoLineCLick}
                columns={PO_TAB_COLUMN}
                data={_.get(data, ['message'], [])}
                isLoading={isFetching}
            />

            {(status != CANCELLED) ? (
                <>
                    <div className="mt-5" />
                    <ChakraTable
                        header
                        size={"sm"}
                        creatable={(status == PROCEEDED_TO_SHIPPING || status == SHIPPED || status == DELAYED) ? undefined : {
                            onClick: createANewPallet,
                            text: "Create a new pallet",
                            isLoading
                        }}
                        title="Pallets"
                        onSelected={onPalletTableLineClick}
                        columns={PALLET_TAB_COLUMNS}
                        data={_.get(palletList, ['message'], [])}
                    /></>
            ) : null}
            <PoLinesDrawer data={selectedPo} isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
        </>
    )
}

export default GeneralTab