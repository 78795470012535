import _ from "lodash";
import { COMMENT } from "../../consts/methods.consts";
import { useState } from "react";
import { FormInput } from "../form-controllers/FormInput";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { ToastService } from '../../services/toast.service';
import { useExecuteMutation, useMethodQuery } from "../../services/api.service";
import { Avatar, Button, HStack, VStack, useToast } from "@chakra-ui/react";

interface ICommentsProps {
    doctype: string,
    ref_doctype: string | undefined,
    status?: string,
    isDisabled?: boolean
}

const EMPTY_FUNC = () => { }

const Comments = ({ doctype, ref_doctype, isDisabled }: ICommentsProps) => {

    const [mutation] = useExecuteMutation()
    const toast = new ToastService(useToast())
    const [editingCommentId, setEditingIdComment] = useState<String>("")

    const formik = useFormik({
        initialValues: {
            comment: ''
        },
        onSubmit: () => { }
    })

    const { data } = useMethodQuery({
        method: COMMENT.GET_COMMENTS,
        body: {
            doctype,
            ref_doctype
        }
    })

    const commentList = _.get(data, "message", [])

    console.log('comment', data)

    const onDeleteClick = async (comment: any) => {
        try {
            if (!_.get(comment, "name")) throw new Error("Comment not found");

            await mutation({
                method: COMMENT.DELETE_COMMENT,
                body: {
                    id: _.get(comment, "name")
                }
            }).unwrap()

            toast.setDescription("Comment deleted successfully").showSuccessToast()
        } catch (error) {
            toast.setDescription("Sorry, Unable to delete comment!").showErrorToast()
        }
    }

    const onAddComment = async () => {
        try {
            const newComment = _.get(formik, ["values", "comment"], '')

            if (!newComment.trim()) throw new Error("Comment not found")

            let body: any = {
                doctype,
                ref_doctype,
                comment: newComment
            }

            if (editingCommentId) {
                body['id'] = editingCommentId
            }

            await mutation({
                method: editingCommentId ? COMMENT.UPDATE_COMMENT : COMMENT.ADD_COMMENT,
                body
            }).unwrap()

            formik.resetForm()
            setEditingIdComment('')

            toast.setDescription("Comment added successfully").showSuccessToast()
        } catch (error) {
            toast.setDescription("Sorry, Unable to add comment!").showErrorToast()
        }
    }

    const commentMarkup = commentList.map((comment: any) =>
        comment?.comment_type == "Comment" ? (
            <Comment
                {...comment}
                onDeleteClick={() => onDeleteClick(comment)}
                formik={formik}
                editingCommentId={editingCommentId}
                setEditingIdComment={setEditingIdComment}
                isDisabled={isDisabled}
            />
        ) : null
    )

    return (
        <>
            <div className="divider-y-2 flex flex-col overflow-auto">
                {commentMarkup.length === 0 ? <div className="mb-3 opacity-50 font-medium">No comments to show</div> : commentMarkup}
            </div>
            <div>
                <FormInput classNames="border-gray-400 bg-white rounded" variant="textArea" formik={formik} name="comment" isDisabled={isDisabled} />
                <div className="flex w-full justify-end mt-3">
                    <Button isDisabled={!Boolean(_.get(formik, ["values", "comment"], '').trim())} className='' autoFocus={false} colorScheme={"blue"} onClick={onAddComment}>
                        {editingCommentId ? 'Update' : 'Comment'}
                    </Button>
                </div>
            </div>
        </>
    )
}

export default Comments;

interface ICommentProps {
    creation?: any,
    name?: any,
    comment_email?: any
    content?: any,
    onDeleteClick: any,
    formik: any,
    setEditingIdComment: any,
    isDisabled: boolean

}

const Comment = ({ onDeleteClick = EMPTY_FUNC, name, creation, content, comment_email, isDisabled, formik, setEditingIdComment = EMPTY_FUNC }: ICommentProps) => {
    const currentUser = useSelector((state: any) => state.common.user || '')

    const onEditClick = () => {
        formik.setFieldValue('comment', content);
        setEditingIdComment(name)
    }

    return (
        <HStack pb={5} alignItems={"flex-start"}>
            <div className="pt-0.5">
                <Avatar name={comment_email} />
            </div>
            <VStack alignItems={"flex-start"}>
                <HStack>
                    <p className="!mt-0 font-semibold">{comment_email}</p>
                    <p className="!mt-0 font-semibold">{creation}</p>
                </HStack>
                <p className="!mt-0">{content}</p>
                {(comment_email == currentUser) && (
                    <HStack>
                        <button onClick={onEditClick} disabled={isDisabled} className="!mt-0 text-blue-400 cursor-pointer disabled:cursor-default disabled:opacity-50">Edit</button>
                        <button onClick={onDeleteClick} disabled={isDisabled} className="!mt-0 text-red-400 cursor-pointer disabled:cursor-default disabled:opacity-50">Delete</button>
                    </HStack>
                )}
            </VStack>
        </HStack>
    )
}