import { Button, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Input, DrawerFooter, Box } from "@chakra-ui/react";
import _ from "lodash";
import ChakraTable from "../../../components/chakra-table/ChakraTable";
import { PO } from "../../../consts/methods.consts";
import { useMethodQuery } from "../../../services/api.service";

interface IPoLinesDrawerProps {
    isOpen: any,
    onClose: any,
    onOpen: any,
    data: any
}

export const COLUMN = [
    {
        header: "Item Code",
        accessor: "item_code",
    },
    {
        header: "Item Name",
        accessor: "item_name",
    },
    {
        header: "Quantity",
        accessor: "quantity"
    },
    {
        header: "Picked Quantity",
        accessor: "picked_quantity"
    },
    {
        header: "Remaining Quantity",
        accessor: "remaining_quantity"
    },
    {
        header: "Picking Status",
        accessor: 'picking_status',
        condition: {
            "NOT PICKED": "red",
            "PICKED": "green"
        }
    }
]

const PoLinesDrawer = ({ isOpen, onClose, onOpen, data: _data }: IPoLinesDrawerProps) => {

    const { data } = useMethodQuery({
        method: PO.GET_PO_ITEM_LIST,
        body: {
            id: _data.name
        }
    })

    return (
        <>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                size={'xl'}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Order : {_.get(_data, 'name')}</DrawerHeader>

                    <DrawerBody>
                        <Box borderWidth={1} borderRadius={"md"} shadow={'sm'}>
                            <ChakraTable
                                size={"sm"}
                                disablePointer
                                onSelected={() => { }}
                                columns={COLUMN}
                                data={_.get(data, 'message')}
                            />
                        </Box>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant='outline' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        {/* <Button colorScheme='blue'>Save</Button> */}
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default PoLinesDrawer;