import { Badge, Center, HStack, Menu, MenuButton, MenuItem, MenuList, Td, Text, Tr } from "@chakra-ui/react";
import { FaEllipsisH } from "react-icons/fa";
import React from 'react';

const TrComponent = ({ item, columns, onSelected, disablePointer }: any) => {

    function selectedRow(row: any) {
        onSelected(row)
    }

    return (
        <Tr _hover={{ bg: disablePointer ? '#fff' : "#EBEBEB" }} cursor={disablePointer ? '' : 'pointer'}>
            {columns.map((col: any, index: any) => <Td onClick={!col.options ? () => selectedRow(item) : () => {
            }} key={index}>
                {col?.customRenderer ? col.customRenderer(item[columns[index].accessor], item) : (
                    <>
                        {col.condition ? <Badge
                            colorScheme={col.condition[item[columns[index].accessor]]}>{item[columns[index].accessor]}</Badge> :
                            <Text className={columns[index]?.className ? columns[index]?.className : undefined}>{item[columns[index]?.accessor]}</Text>}

                        {(col.options && !col?.disabled) ? <Center>
                            <HStack spacing={5}>
                                <Menu>
                                    <MenuButton>
                                        <FaEllipsisH />
                                    </MenuButton>
                                    <MenuList>
                                        {col.options.map((actionItem: any, index: any) => <MenuItem onClick={() => {
                                            actionItem.action(item)
                                        }} key={index}>{actionItem.label}</MenuItem>)}
                                    </MenuList>
                                </Menu>
                            </HStack>
                        </Center> : null}
                    </>
                )}
            </Td>
            )}
        </Tr>
    )
}
export default TrComponent;