import _ from 'lodash'
import * as Yup from "yup";
import { SHIPMENT } from "../../../consts/methods.consts"
import ChakraModal from "../../../components/chakra-modal/ChakraModal"
import { useFormik } from "formik"
import { AiFillDelete } from "react-icons/ai"
import FormSearchSelect from "../../../components/form-controllers/FormSearchSelect"
import { ToastService } from '../../../services/toast.service'
import { useEffect, useState } from "react"
import { useExecuteMutation, useMethodQuery } from "../../../services/api.service"
import { Box, HStack, Text, useToast, VStack } from "@chakra-ui/react"
import { FormSelect } from '../../../components/form-controllers/FormSelect'

interface ICreateNewShipmentModal {
    isOpen: any,
    onOpen: any,
    onClose: any
}

const shippingModes = [
    {
        key: "Air",
        text: "Air"
    },
    {
        key: "Sea Freight",
        text: "Sea Freight"
    },
    {
        key: "Curious",
        text: "Curious"
    },
]

const typesOfTheFreight = [
    {
        key: "20 feet",
        text: "20 feet"
    },
    {
        key: " 40 feet",
        text: "40 feet"
    },
    {
        key: "LCL",
        text: "LCL"
    },
    {
        key: "Loose cartons",
        text: "Loose cartons"
    },
]

const CreateNewShipmentModal = ({ isOpen, onClose, onOpen }: ICreateNewShipmentModal) => {
    const [create, { isLoading, }] = useExecuteMutation()
    const [selectedOrders, setSelectedOrders] = useState<any>([])
    const toast = new ToastService(useToast())

    const { data } = useMethodQuery({
        method: SHIPMENT.GET_PO_LIST_FOR_DROPDOWN,
    })

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            mode_of_shipment: Yup.string().required("Mode of shipment is required!"),
            container_type: Yup
                .string()
                .when('mode_of_shipment', {
                    is: 'Sea Freight',
                    then: Yup.string().required('Container type is required'),
                }),
        }),
        onSubmit: async (values: any) => {
            try {
                if (!_.isEmpty(selectedOrders)) {
                    await create({
                        method: SHIPMENT.CREATE_SHIPMENT,
                        body: {
                            shipping_orders: selectedOrders.map((line: any) => {
                                return {
                                    'purchase_order': line
                                }
                            }),
                            mode_of_shipment: _.get(values, 'mode_of_shipment'),
                            container_type: _.get(values, 'container_type') ? _.get(values, 'container_type') : null
                        }
                    }).unwrap();

                    toast.setDescription("Shipment is created successfully").showSuccessToast()
                    formik.resetForm()
                }
                onClose();
            } catch (e: any) {
                toast.setDescription("Unable to create shipment").showErrorToast()
            }
        }
    })

    useEffect(() => {
        if (isOpen) {
            setSelectedOrders([])
            formik.resetForm()
        }
    }, [isOpen])

    useEffect(() => {
        const selectedPo = _.get(formik, ["values", "po"])
        if (selectedPo) {
            const index = selectedOrders.findIndex((line: any) => line == selectedPo)

            if (index == -1) {
                setSelectedOrders((prev: any) => [selectedPo, ...prev])
            }
        }
    }, [JSON.stringify(formik.values)])

    const onDelete = (item: any) => {
        const filteredLines = selectedOrders.filter((line: any) => line != item)
        setSelectedOrders(filteredLines)
    }

    const orderMarkup = selectedOrders.map((line: any) => <OrderItem po={line} onDelete={onDelete} />)

    return (
        <ChakraModal disablePrimaryButton={_.isEmpty(_.get(formik, ["values", "po"]))} primaryButtonText="Save"
            primaryAction={formik.submitForm} modalTitle="New Shipment" isOpen={isOpen} onClose={onClose}>
            <VStack spacing={2}>
                <FormSearchSelect isClearable={false} options={_.get(data, ['message'], [])} isRequired
                    label="Orders" formik={formik} name="po" />
                {orderMarkup}
            </VStack>
            <VStack paddingY={4}>
                <FormSelect placeholder='Select a mode of Shipment' options={shippingModes} isRequired formik={formik} name="mode_of_shipment"
                    label="Mode of Shipment" />
            </VStack>
            {_.get(formik, ['values', 'mode_of_shipment'], '') == 'Sea Freight' && <VStack py={1}>
                <FormSelect placeholder='Select' options={typesOfTheFreight} isRequired formik={formik} name="container_type"
                    label="Container type" />
            </VStack>
            }
        </ChakraModal>
    )
}

const OrderItem = ({
    po, onDelete = () => { }
}: any) => {
    return (
        <Box padding={4} borderRadius={'md'} w={'full'} borderWidth={'1px'}>
            <HStack justifyContent={"space-between"} alignItems={'center'}>
                <Text fontWeight={"semibold"}>{po}</Text>
                <AiFillDelete onClick={() => onDelete(po)} className='text-[25px] cursor-pointer transition-all' />
            </HStack>
        </Box>
    )
}

export default CreateNewShipmentModal