import _ from "lodash";
import { PO } from "../../consts/methods.consts";
import React from 'react'
import Comments from "../../components/comments/Comments";
import PageHeader from "../../components/page-header/PageHeader";
import Activities from "../../components/activities/Activities";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Attachments from "../../components/attachments/Attachments";
import { InnerLoading } from "../../components/inner-loading/InnerLoading";
import OrderGeneralTab from "./components/tabs/OrderGeneralTab";
import { useMethodQuery } from "../../services/api.service";
import { Badge, Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from '@chakra-ui/react';

const PoViewPage = () => {
    const { po_id: id } = useParams()
    const toast = useToast()
    const [isLoading, setIsLoading] = React.useState(true);

    const { data, isFetching } = useMethodQuery({
        method: PO.GET_PO,
        body: {
            id
        }
    })

    const status = _.get(data, ['message', 'status'])

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        formik.setFieldValue('po_number', _.get(data, ['message', 'po_number']));
        formik.setFieldValue('order_date', _.get(data, ['message', 'order_date']));
        formik.setFieldValue('delivery_date', _.get(data, ['message', 'delivery_date']));
    }, [JSON.stringify(data?.message)])

    useEffect(() => {
        if (!isFetching) {
            setIsLoading(false)
        }
    }, [isFetching])

    const statusRenderer = () => {
        if (status == 'Processing') {
            return (
                <Badge colorScheme='yellow'>Processing</Badge>
            )
        }

        if (status == 'Proceeded to shipping') {
            return (
                <Badge colorScheme='green'>Proceeded to shipping</Badge>
            )
        }

        return (
            <Badge colorScheme='pink'>Open</Badge>
        )
    }

    return (
        <>
            <PageHeader backHandler statusRenderer={statusRenderer()} primaryButton={false} title={`Order: ${id}`}
                subTitle={"Order View"} buttonText={"Save"} />

            <Tabs isLazy pb={5} mx={3} className="bg-slate-200 rounded-md">
                <TabList className="pb-0.5 rounded-md">
                    <Tab _focus={{ border: '0px', borderBottom: '2px' }}>General</Tab>
                    <Tab _focus={{ border: '0px', borderBottom: '2px' }}>Comments</Tab>
                    <Tab _focus={{ border: '0px', borderBottom: '2px' }}>Attachments</Tab>
                    <Tab _focus={{ border: '0px', borderBottom: '2px' }}>Activities</Tab>

                </TabList>
                {isLoading ? 
                 <InnerLoading/> :
                    <TabPanels>
                        <TabPanel px={0}>
                            <OrderGeneralTab />
                        </TabPanel>
                        <TabPanel>
                            <Comments doctype="Purchase Order" ref_doctype={id} />
                        </TabPanel>
                        <TabPanel>
                            <Attachments doctype="Purchase Order" ref_doctype={id} />
                        </TabPanel>
                        <TabPanel>
                            <Activities doctype="Purchase Order" ref_doctype={id} />
                        </TabPanel>
                    </TabPanels>}
            </Tabs >
        </>
    )
}

export default PoViewPage