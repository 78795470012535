import _ from "lodash"
import { CreatableSelect } from "chakra-react-select"
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react"

type Option = {
    label: String,
    value: any
}

interface IFormInputSelect {
    classNames?: string,
    formik: any,
    name: string,
    label: string,
    isRequired?: boolean,
    isDisabled?: boolean,
    isLoading?: boolean,
    defaultValue?: any,
    options: Array<Option>,
    isSearchable?: boolean,
    metaDispatcher?: any,
    isMulti?: boolean,
    isClearable?: boolean,
    components?: any,
    placeholder?: string | number | undefined
}

const FormInputSelect = ({ isClearable = true, placeholder = '', formik, name, label, isRequired = false, isDisabled = false, isLoading = false, options = [] }: IFormInputSelect) => {

    const changeHandler = (e: any) => {
        formik.setFieldValue(name, e.value)
    }

    return (
        <FormControl isRequired={isRequired} isInvalid={Boolean(formik.submitCount && formik.errors[name])}>
            <FormLabel>{label}</FormLabel>
            <CreatableSelect
                name={name}
                options={options}
                placeholder={placeholder ? placeholder : "Select"}
                closeMenuOnSelect={true}
                onChange={changeHandler}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
            // onInputChange

            />
            {(formik.submitCount && formik.errors[name]) ? <FormErrorMessage>{formik.errors[name]}</FormErrorMessage> : null}
        </FormControl>
    )
}

export default FormInputSelect;