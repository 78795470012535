import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react"
import { Select } from "chakra-react-select"
import _ from "lodash"
import { useEffect, useState } from "react"

type Option = {
    label: String,
    value: any
}

interface IFormSearchSelect {
    classNames?: string,
    formik: any,
    name: string,
    label: string,
    isRequired?: boolean,
    isDisabled?: boolean,
    isLoading?: boolean,
    defaultValue?: Option,
    options: Array<Option>,
    isSearchable?: boolean,
    metaDispatcher?: any,
    isMulti?: boolean,
    isClearable?: boolean,
    components?: any,
    placeholder?: string | number | undefined
}

const FormSearchSelect = ({ isClearable = true, placeholder = '', components, isMulti = false, metaDispatcher = () => { }, isSearchable = true, formik, defaultValue, name, label, isRequired = false, isDisabled = false, isLoading = false, options = [] }: IFormSearchSelect) => {
    const [df, setDf] = useState<any>('');

    useEffect(() => {
        if (!_.isEmpty(defaultValue)) {
            setDf(defaultValue)
        }
    }, [defaultValue])

    return (
        <FormControl isRequired={isRequired} isInvalid={Boolean(formik.submitCount && formik.errors[name])}>
            <FormLabel>{label}</FormLabel>
            <Select
                className="basic-single"
                defaultValue={df}
                onChange={(e: any) => {
                    if (!_.isEmpty(e)) {
                        const { value, ...rest } = e;
                        formik.setFieldValue(name, value);
                        metaDispatcher(e)
                    } else {
                        formik.setFieldValue(name, '');
                    }
                }}
                isMulti={isMulti}
                placeholder={placeholder ? placeholder : "Search"}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                name={name}
                components={components ? components : undefined}
                options={options}
            />
            {(formik.submitCount && formik.errors[name]) ? <FormErrorMessage>{formik.errors[name]}</FormErrorMessage> : null}
        </FormControl>
    )
}

export default FormSearchSelect;