import _ from "lodash";
import React from 'react'
import Comments from "../../components/comments/Comments";
import { Select } from "@chakra-ui/react"
import PageHeader from "../../components/page-header/PageHeader";
import { SHIPMENT } from "../../consts/methods.consts";
import Activities from "../../components/activities/Activities";
import { useState } from "react";
import GeneralTab from "./components/tabs/GeneralTab";
import Attachments from "../../components/attachments/Attachments";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import InformationTab from "./components/tabs/InformationTab";
import { InnerLoading } from "../../components/inner-loading/InnerLoading";
import { ToastService } from "../../services/toast.service";
import { SHIPMENT_TYPES } from "../../consts/type.consts";
import ShippingSummaryModal from "./components/ShippingSummaryModal";
import ShipmentCancellationModal from "./components/ShipmentCancellationModel";
import { useExecuteMutation, useMethodQuery } from "../../services/api.service";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Badge, useDisclosure, useToast, Button, HStack, VStack, } from '@chakra-ui/react'

const { PROCEEDED_TO_SHIPPING, PROCESSING, CANCELLED, SHIPPED, DELAYED } = SHIPMENT_TYPES

const shipmentTypes = [
    {
        key: "Proceeded to shipping",
        text: "Proceeded to shipping"
    },
    {
        key: "Shipped",
        text: "Shipped"
    },
    {
        key: "Delayed",
        text: "Delayed"
    }
]

const ViewShipmentPage = () => {
    const [create] = useExecuteMutation()
    const { onOpen: onSSModalOpen, isOpen: isSSModalOpen, onClose: onCloseSSModal } = useDisclosure()
    const { onOpen: onCancelModalOpen, onClose: onCloseCancelModal, isOpen: isCancelModalOpen } = useDisclosure()
    const [shippingSummaryLines, setShippingSummaryLines] = useState<any>([])
    const [isLoading, setIsLoading] = React.useState(true);

    const { shipment_id: id } = useParams();

    const toast: ToastService = new ToastService(useToast());
    const [action] = useExecuteMutation()

    const { data: shipment, isFetching } = useMethodQuery({
        method: SHIPMENT.GET_SHIPMENT_STATUS,
        body: {
            id
        }
    })

    const { data: shipmentType } = useMethodQuery({
        method: SHIPMENT.GET_SHIPMENT_TYPE,
        body: {
            id
        }
    })

    const { data: containerType } = useMethodQuery({
        method: SHIPMENT.GET_CONTAINER_TYPE,
        body: {
            id
        }
    })

    const { data: cancellationReason } = useMethodQuery({
        method: SHIPMENT.GET_SHIPMENT_DETAILS,
        body: {
            id
        }
    })

    const { data: shortShipment } = useMethodQuery({
        method: SHIPMENT.GET_SHORT_SHIPMENT_STATE,
        body: {
            id
        }
    })

    const status = _.get(shipment, 'message')
    const type = _.get(shipmentType, 'message')
    const ConType = _.get(containerType, 'message')
    const reason = _.get(cancellationReason, ['message', 'cancellation_reason'])
    const shipmentState = _.get(shortShipment, 'message')

    useEffect(() => {
        if (!isFetching) {
            setIsLoading(false)
        }
    }, [isFetching])

    const onClickProceedToShipping = async () => {
        try {
            const res = await action({
                method: SHIPMENT.PROCEED_TO_SHIPPING,
                body: {
                    id
                }
            }).unwrap()

            const status = _.get(res, ['message', 'status'])
            const summaryLines = _.get(res, ["message", "data", "items"], [])
            setShippingSummaryLines(summaryLines)

            onSSModalOpen()

            if (status == 0) {
                toast.setTitle("The shipment is not shippable").setDescription("Please pick all items that have not been picked").showErrorToast()
                return
            }

            if (status == 1) {
                toast.setTitle('The shipment is proceeded to shipping').showSuccessToast()
                return
            }

            if (status == -1) {
                toast.setTitle('The shipment is not shippable').setDescription("The shipment is not in the processing stage").showErrorToast()
                return
            }

            throw new Error()
        } catch (e: any) {
            toast.setTitle("The shipment is not shippable").showErrorToast()
        }
    }

    const statusRenderer = () => {
        if (status == PROCESSING) {
            return (
                <Badge colorScheme='yellow'>Processing</Badge>
            )
        }

        if (status == PROCEEDED_TO_SHIPPING) {
            return (
                <Badge colorScheme='blue' >Proceeded to shipping</Badge>
            )
        }

        if (status == CANCELLED) {
            return (
                <Badge colorScheme='red'>Cancelled</Badge>
            )
        }

        if (status == SHIPPED) {
            return (
                <Badge colorScheme='green'>Shipped</Badge>
            )
        }

        if (status == DELAYED) {
            return (
                <Badge colorScheme='orange'>Delayed</Badge>
            )
        }

        return (
            <>
                <Badge colorScheme='pink'>Open</Badge>
                {
                    shipmentState == 'Short Shipment' ? <Badge colorScheme='blue'>Short Shipment</Badge> :
                        <Badge colorScheme='green'>Standard Shipment</Badge>
                }
            </>
        )
    }

    const changeHandler = async (e: any) => {
        try {
            await create({
                method: SHIPMENT.UPDATE_SHIPMENT_TYPE,
                body: {
                    shipment_id: id,
                    status: e.target.value
                }
            }).unwrap();

            toast.setDescription("Shipment status updated successfully").showSuccessToast()

        } catch (e: any) {
            toast.setDescription("Unable to update shipment status").showErrorToast()
        }
    }

    const optionMarkup = shipmentTypes?.map((line: any, index: any) => {
        return (
            <option key={index} value={line.key}>{line.text}</option>
        )
    })

    const actionRenderer = (
        <>
            {
                status == 'Proceeded to shipping' || status == 'Delayed' ?
                    (
                        <>
                            <Select defaultValue={status} name="shipment_status" onChange={changeHandler} className="!border-gray-300" borderRadius={'md'} w={'full'} h={"8"}>
                                <option hidden>{status}</option>
                                {!_.isEmpty(shipmentTypes) ? optionMarkup : null}
                            </Select>
                            <Button size={"sm"} onClick={onCancelModalOpen} flexShrink={0} colorScheme="blackAlpha" fontWeight="bold">
                                Cancel
                            </Button>
                        </>
                    ) : null
            }

            {
                (status != 'Proceeded to shipping') && (
                    <>
                        {
                            (status != CANCELLED && status != SHIPPED && status != DELAYED) ? (
                                <Button size={"sm"} onClick={onClickProceedToShipping} flexShrink={0} colorScheme="blue" fontWeight="bold">
                                    Proceed to shipping
                                </Button>
                            ) : null
                        }
                        {
                            (status != CANCELLED && status != SHIPPED && status != DELAYED) ? (
                                <Button size={"sm"} onClick={onCancelModalOpen} flexShrink={0} colorScheme="blackAlpha" fontWeight="bold">
                                    Cancel
                                </Button>
                            ) : null
                        }
                    </>
                )
            }
        </>
    )

    const ShipmentTypeRenderer = () => {
        if (type == 'Air') {
            return (
                <Badge colorScheme='yellow'>Air</Badge>
            )
        }

        if (type == "Sea Freight") {
            if (ConType == "20 feet") {
                return (
                    <Badge colorScheme='green'>Sea Freight-20 feet</Badge>
                )
            }

            if (ConType == "40 feet") {
                return (
                    <Badge colorScheme='green'>Sea Freight - 40 feet</Badge>
                )
            }

            if (ConType == "LCL") {
                return (
                    <Badge colorScheme='green'>Sea Freight - LCL</Badge>
                )
            }

            if (ConType == "Loose cartons") {
                return (
                    <Badge colorScheme='green'>Sea Freight - Loose cartons</Badge>
                )
            }
        }

        if (type == "Curious") {
            return (
                <Badge colorScheme='blue'>Curious</Badge>
            )
        }
    }

    return (
        <>
            <PageHeader actionRenderer={actionRenderer} subTitleRenderer={ShipmentTypeRenderer()} backHandler statusRenderer={statusRenderer()} primaryButton={false} title={`Shipment: ${id}`}
                subTitle={"Shipment"} buttonText={"Save"} />
            <Tabs isLazy pb={5} mx={3} className="bg-slate-200 rounded-md">
                <TabList className="pb-0.5 rounded-md">
                    <Tab _focus={{ border: '0px', borderBottom: '2px' }}>General</Tab>
                    <Tab _focus={{ border: '0px', borderBottom: '2px' }}>Comments</Tab>
                    <Tab _focus={{ border: '0px', borderBottom: '2px' }}>Attachments</Tab>
                    <Tab _focus={{ border: '0px', borderBottom: '2px' }}>Activities</Tab>
                    <Tab _focus={{ border: '0px', borderBottom: '2px' }}>Information</Tab>
                </TabList>
                {isLoading ?
                    <InnerLoading /> :
                    <TabPanels>
                        <TabPanel px={0} pb={0}>
                            <GeneralTab status={status} />
                        </TabPanel>
                        <TabPanel pb={0}>
                            <Comments doctype="Shipment" ref_doctype={id} isDisabled={status == "Cancelled"} />
                        </TabPanel>
                        <TabPanel pb={0}>
                            <Attachments doctype="Shipment" ref_doctype={id} isDisabled={status == "Cancelled"} />
                        </TabPanel>
                        <TabPanel pb={0}>
                            <Activities doctype="Shipment" ref_doctype={id} />
                        </TabPanel>
                        <TabPanel px={0} pb={0}>
                            <InformationTab status={status} />
                        </TabPanel>
                    </TabPanels>}
            </Tabs >
            {status == 'Cancelled' ? <HStack mx={3} py={5} alignItems={"flex-start"}>
                <VStack alignItems={"flex-start"}>
                    <HStack>
                        <p className="!mt-0 font-semibold">Cancellation Reason</p>
                    </HStack>
                    <p className="!mt-0">{reason}</p>
                </VStack>
            </HStack> : null}
            <ShippingSummaryModal isOpen={isSSModalOpen} onClose={onCloseSSModal} data={shippingSummaryLines} />
            <ShipmentCancellationModal isOpen={isCancelModalOpen} onClose={onCloseCancelModal} />
        </>
    )
}

export default ViewShipmentPage